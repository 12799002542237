import React, { useState } from "react";

import {
  Form,
  Input,
  Layout,
  theme,
  Typography,
  Button,
  Divider,
  message,
  Watermark,
  Image,
  ConfigProvider,
} from "antd";

import CmpMenu from "./components/menu/cmp_menu";
import "./App.css";
import PosMessageList from "./components/posMessage/posMessageList";
import logo from "../src/assets/images/bsh_logo.jpeg";
import hugin_logo from "../src/assets/images/hugin_payment_systems.png";
import trTR from "antd/locale/tr_TR";
import HomePage from "./components/reports/home_page";
import BranchList from "./components/branch/branch_list";
import DeviceList from "./components/device/device_list";
import { login } from "./infrastructure/ttposwebservice";
import UserList from "./components/user/user_list";

const { Header, Content, Footer, Sider } = Layout;

const loginError = () => {
  message.info("Kullanıcı adı veya şifre hatalıdır!");
};

const App = () => {
  const [menu, setMenu] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState("");
  const [form] = Form.useForm();

  const [fields, setFields] = useState([
    {
      name: ["email"],
      value: "",
    },
    {
      name: ["password"],
      value: "",
    },
  ]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onClick = (e) => {
    if (e.key == 6) {
      setLoggedIn(false);
    } else {
      setMenu(e.key);
    }
  };

  return (
    <ConfigProvider
      theme={{ token: { colorPrimary: "#0056a4" } }}
      locale={trTR}
    >
      <div>
        {!loggedIn && (
          <Watermark content="B/S/H/ Yönetim Paneli">
            <div className="appBg">
              <Form
                name="login_form"
                className="loginForm"
                style={{ maxWidth: 1000 }}
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 20,
                }}
                onFieldsChange={(changedFields, allFields) => {
                  setFields(allFields);
                }}
                fields={fields}
                onFinish={(values) => {
                  if (values.userName === undefined) {
                    return;
                  }

                  if (values.password === undefined) {
                    return;
                  }

                  login(values).then((control) => {
                    console.log("Log in result");
                    console.log(control);
                    if (control) {
                      setLoggedInUser(values.userName);
                      setLoggedIn(true);
                    } else {
                      loginError();
                    }
                  });
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <Image src={logo} width={200} preview={false}></Image>
                </div>
                <Divider style={{ borderColor: "black" }}>
                  <Typography.Title
                    style={{ textAlign: "center", fontSize: 22 }}
                  >
                    Kullanıcı Girişi
                  </Typography.Title>
                </Divider>
                <Form.Item
                  label="E-Posta"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: "Kullanıcı adı zorunludur!",
                    },
                  ]}
                >
                  <Input placeholder="Kullanıcı adı giriniz" />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Şifre zorunludur!",
                    },
                  ]}
                >
                  <Input.Password placeholder="Şifre giriniz" />
                </Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Giriş Yap
                </Button>
                <Divider style={{ borderColor: "black" }}>
                  <div style={{ textAlign: "center" }}>
                    <Image src={hugin_logo} width={200} preview={false}></Image>
                  </div>
                </Divider>

                <div></div>
              </Form>
            </div>
          </Watermark>
        )}

        {loggedIn && (
          <Layout>
            <Header style={{ height: 5 }}></Header>
            <Content style={{ height: "100vh" }}>
              <Layout>
                <Sider
                  width={250}
                  style={{ height: "100vh", backgroundColor: "white" }}
                >
                  <CmpMenu onClick={onClick} logedInUser={loggedInUser} />
                </Sider>
                <Content style={{ margin: 20 }}>
                  {menu == 1 && <HomePage />}
                  {menu == 2 && <BranchList />}
                  {menu == 3 && <DeviceList />}
                  {menu == 4 && <PosMessageList />}
                  {menu == 5 && <UserList />}
                </Content>
              </Layout>
            </Content>

            <Footer
              style={{
                textAlign: "center",
              }}
            >
              Bizim Toptan App v.1.0.1
            </Footer>
          </Layout>
        )}
      </div>
    </ConfigProvider>
  );
};
export default App;
