import React from "react";
import { Form, Input, Tabs, Space, Button, InputNumber, Select } from "antd";

const BranchForm = ({ onChange, fields, onCancel, onOK }) => {
  const Tab1 = () => {
    return (
      <div>
        <Form.Item
          name="branchCode"
          label="Şube Kodu"
          rules={[
            {
              required: true,
              message: "Şube Kodu alanı zorunludur!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="branchName"
          label="Şube Açıklama"
          rules={[
            {
              required: true,
              message: "Şube Kodu alanı zorunludur!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="id"></Form.Item>
      </div>
    );
  };

  const Tab2 = () => {
    return (
      <div>
        <Form.Item name="line1" label="Fiş Başlığı 1">
          <Input />
        </Form.Item>
        <Form.Item name="line2" label="Fiş Başlığı 2">
          <Input />
        </Form.Item>
        <Form.Item name="line3" label="Fiş Başlığı 3">
          <Input />
        </Form.Item>
        <Form.Item name="line4" label="Fiş Başlığı 4">
          <Input />
        </Form.Item>
        <Form.Item name="line5" label="Fiş Başlığı 5">
          <Input />
        </Form.Item>
        <Form.Item name="line6" label="Fiş Başlığı 6">
          <Input />
        </Form.Item>
        <Form.Item name="line7" label="Fiş Başlığı 7">
          <Input />
        </Form.Item>
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "Şube Kartı",
      children: Tab1(),
    },
    {
      key: "2",
      label: "Fiş Başlığı",
      children: Tab2(),
    },
  ];

  const onTabChange = (key) => {
    console.log(key);
  };
  return (
    <Form
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      name="semptom_form"
      layout="horizontal"
      fields={fields}
      onFinish={onOK}
      onCancel={onCancel}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
    >
      <Form.Item name="id"></Form.Item>
      <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />

      <Space>
        <Button type="primary" htmlType="submit">
          Kaydet
        </Button>
        <Button type="primary" danger onClick={onCancel}>
          Vazgeç
        </Button>
      </Space>
    </Form>
  );
};
export default BranchForm;
