import React, { useEffect, useRef, useState } from "react";
import {
  SearchOutlined,
  FileExcelOutlined,
  CloudSyncOutlined,
  UploadOutlined,
  FileSearchOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import {
  Table,
  Input,
  Button,
  DatePicker,
  Space,
  Tag,
  Checkbox,
  Select,
  message,
  Modal,
} from "antd";
import {
  getAllBranches,
  getAllPosMessages,
  getExcelReportLink,
  sendAllWaiting,
  sendToService,
  showJsonString,
} from "../../infrastructure/ttposwebservice";
import dayjs from "dayjs";

const PosMessageList = () => {
  const { TextArea } = Input;

  const [messageApi, contextHolder] = message.useMessage();
  const [json, setJson] = useState("");
  const [extra2, setExtra2] = useState("");
  const [open, setOpen] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allBranches, setAllBranches] = useState(true);
  const [branches, setBranches] = useState([]);
  const [branchId, setBranchId] = useState(-1);

  const [sortedInfo, setSortedInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  let startDateState = useRef();
  let endDateState = useRef();

  const [excelLink, setExcelLink] = useState("");

  let [filteredData] = useState();
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    var newDate = dayjs(new Date());

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    setExcelLink(
      getLink(
        new Date(year, month, day).getTime(),
        new Date(year, month, day, 23, 59, 59).getTime()
      )
    );

    startDateState.current = new Date(year, month, day).getTime();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();

    getAllBranches().then((branchList) => {
      getAllPosMessages(
        searchText,
        new Date(year, month, day).getTime(),
        new Date(year, month, day, 23, 59, 59).getTime(),
        allBranches ? -1 : branchId
      ).then((result) => {
        result.map((el) => {
          let date = new Date(el.tranDate);
          el.actDate =
            date.toLocaleDateString() + " - " + date.toLocaleTimeString();
        });

        var valueItems = branchList.map(function (v) {
          return {
            value: v.id,
            label: v.branchCode + "- " + v.branchName,
          };
        });
        console.log(valueItems);
        setBranches(valueItems);

        setLoading(false);
        setData(result);
      });
    });
  }, []);

  /*

  useEffect(() => {
    if (isNaN(startDate) || isNaN(endDate)) {
      return;
    }
    
    loadData();
  }, [startDate, endDate]);*/

  const loadData = async () => {
    setLoading(true);
    setExcelLink(
      getLink(startDateState.current, endDateState.current, branchId)
    );

    getAllPosMessages(
      searchText,
      startDateState.current,
      endDateState.current,
      allBranches ? -1 : branchId
    ).then((result) => {
      result.map((el) => {
        let date = new Date(el.tranDate);
        el.actDate =
          date.toLocaleDateString() + " - " + date.toLocaleTimeString();
      });
      setLoading(false);
      setData(result);
    });
  };

  const getLink = (sDate, eDate, branchId) => {
    console.log(sDate);
    return getExcelReportLink(
      searchText,
      sDate,
      eDate,
      allBranches ? -1 : branchId
    );
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };

  const globalSearch = async (e) => {
    console.log("Global search ");
    await loadData();
    filteredData = await data.filter((value) => {
      return value.serialNo.toLowerCase().includes(searchText.toLowerCase());
    });

    setData(filteredData);
  };

  const sendAll = async (e) => {
    setLoading(true);
    sendAllWaiting().then((value) => {
      setLoading(false);
      message.info("Servise gönderim tamamlandı");
    });
  };

  const sendToServer = async (record) => {
    setLoading(true);
    sendToService(record.id).then((result) => {
      setLoading(false);
      message.info("Servise gönderim bitti");
    });
    /*
    sendToService(id).then((value) => {
      setLoading(false);
      message.info("Servise gönderim tamamlandı");
    });*/
  };

  const showJson = async (record) => {
    setLoading(true);
    console.log(record);
    showJsonString(record.id).then((result) => {
      setLoading(false);
      console.log(result);
      setJson(JSON.stringify(result));
      setOpen(1);
    });
  };
  const showError = async (record) => {
    setExtra2(record);
    setOpen(2);
  };

  const onStartDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();

    //setStartDate(new Date(year, month, day));
    startDateState.current = new Date(year, month, day).getTime();
  };

  const onEndDateChange = (date, dateString) => {
    var newDate = dayjs(date);

    var day = newDate.date();
    var month = newDate.month();
    var year = newDate.year();
    endDateState.current = new Date(year, month, day, 23, 59, 59).getTime();
  };

  const checkChanged = (e) => {
    setAllBranches(e.target.checked);
  };

  const onSelectedBranchChanged = (e) => {
    setBranchId(e);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      align: "center",
      width: 190,
      sorter: (a, b) => a.id > b.id,
      sortOrder: sortedInfo.columnKey === "id" && sortedInfo.order,
    },
    {
      title: "Servis",
      dataIndex: "id",
      align: "center",
      width: 190,
      render: (_, record) => (
        <Space>
          {record.state == 1 ? (
            <Space>
              <Tag color="blue">Gönderilmiş</Tag>
              <Tag color="red">{record.serviceSendCount}</Tag>
            </Space>
          ) : (
            <Space>
              <Button
                icon={<UploadOutlined />}
                onClick={() => sendToServer(record)}
              ></Button>
              <Button
                icon={<FileSearchOutlined />}
                onClick={() => showJson(record)}
              ></Button>
              <Button
                icon={<InfoOutlined />}
                onClick={() => showError(record.extra2)}
              ></Button>
            </Space>
          )}
        </Space>
      ),
    },
    {
      title: "Tarih",
      dataIndex: "actDate",
      align: "center",
      width: 190,
      sorter: (a, b) => a.createdAt > b.createdAt,
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
    },
    {
      title: "Cihaz Seri No",
      dataIndex: "serialNo",
      align: "center",
      sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
    },
    {
      title: "İşlem Türü",
      dataIndex: "oprType",
      align: "center",
      render: (oprType) => (
        <Space>
          <Tag
            color={oprType == 0 ? "blue" : "red"}
            style={{ width: 70, textAlign: "center" }}
          >
            {oprType == 0 ? "Tahsilat" : oprType == 1 ? "İptal" : "İade"}
          </Tag>
        </Space>
      ),
      sorter: (a, b) => a.messageType > b.messageType,
      sortOrder: sortedInfo.columnKey === "messageType" && sortedInfo.order,
    },
    {
      title: "İşlem Durumu",
      dataIndex: "errorCode",
      align: "center",
      sorter: (a, b) => a.serialNo.localeCompare(b.serialNo),
      sortOrder: sortedInfo.columnKey === "serialNo" && sortedInfo.order,
      render: (errorCode) => (
        <Space>
          <Tag
            color={errorCode == 0 ? "green" : "red"}
            style={{ width: 70, textAlign: "center" }}
          >
            {errorCode == 0 ? "Başarılı" : "Başarısız"}
          </Tag>
          <Tag style={{ width: 30, textAlign: "center" }}> {errorCode}</Tag>
        </Space>
      ),
    },

    {
      title: "Batch No",
      dataIndex: "batchNo",
      align: "center",
      sorter: (a, b) => a.batchNo > b.batchNo,
      sortOrder: sortedInfo.columnKey === "batchNo" && sortedInfo.order,
    },
    {
      title: "Stan No",
      dataIndex: "stanNo",
      align: "center",
      sorter: (a, b) => a.stanNo > b.stanNo,
      sortOrder: sortedInfo.columnKey === "stanNo" && sortedInfo.order,
    },
    {
      title: "Kart No",
      dataIndex: "cardNo",
      align: "center",
      sorter: (a, b) => a.cardNo.localeCompare(b.cardNo),
      sortOrder: sortedInfo.columnKey === "cardNo" && sortedInfo.order,
    },
    {
      title: "Auth Code",
      dataIndex: "authCode",
      align: "center",
      sorter: (a, b) => a.authCode.localeCompare(b.authCode),
      sortOrder: sortedInfo.columnKey === "authCode" && sortedInfo.authCode,
    },
    {
      title: "Acquirer ID",
      dataIndex: "acquirerID",
      align: "center",
      sorter: (a, b) => a.acquirerID > b.acquirerID,
      sortOrder: sortedInfo.columnKey === "acquirerID" && sortedInfo.acquirerID,
    },
    {
      title: "Terminal No",
      dataIndex: "terminalID",
      align: "center",
      sorter: (a, b) => a.acquirerID > b.acquirerID,
      sortOrder: sortedInfo.columnKey === "acquirerID" && sortedInfo.acquirerID,
    },
    {
      title: "Ref No",
      dataIndex: "refNo",
      sorter: (a, b) => a.refNo.localeCompare(b.refNo),
      sortOrder: sortedInfo.columnKey === "refNo" && sortedInfo.refNo,
    },

    {
      title: "Tutar",
      dataIndex: "amount",
      align: "right",
      render: (amount) => (
        <Space>
          <Tag color="volcano">{(amount / 100.0).toFixed(2)}</Tag>
        </Space>
      ),
      sorter: (a, b) => a.amount > b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
    },
    {
      title: "Extra Info 1",
      dataIndex: "extra1",
      sorter: (a, b) => a.extra1.localeCompare(b.extra1),
      sortOrder: sortedInfo.columnKey === "extra1" && sortedInfo.extra1,
    },
  ];

  return (
    <div>
      <Modal
        title="Json"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <TextArea rows={4} value={json} />
      </Modal>
      <Modal
        title="Extra 2"
        open={open == 2}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <TextArea rows={4} value={extra2} />
      </Modal>
      <Space direction="vertical">
        <Space>
          <DatePicker
            format={dateFormat}
            defaultValue={dayjs(startDate)}
            onChange={onStartDateChange}
          />
          <DatePicker
            format={dateFormat}
            defaultValue={dayjs(endDate)}
            onChange={onEndDateChange}
          />

          <Input
            style={{ width: 200, marginLeft: 10, marginRight: 10 }}
            placeholder="Filtre"
            type="text"
            allowClear
            value={searchText}
            onChange={handleInputChange}
          />
          <Button
            onClick={globalSearch}
            type="primary"
            icon={<SearchOutlined />}
          >
            Listele
          </Button>
          <Button type="link" href={excelLink} icon={<FileExcelOutlined />}>
            Excel Rapor
          </Button>
          <Button
            onClick={sendAll}
            danger
            icon={<CloudSyncOutlined />}
            disabled
          >
            Sunucuya Gönder
          </Button>
          <Tag color="orange">({data.length})</Tag>
        </Space>
        <Space>
          <Checkbox checked={allBranches} onChange={checkChanged}>
            Tüm Şubeler
          </Checkbox>
          {!allBranches && (
            <Select
              options={branches}
              onChange={onSelectedBranchChanged}
              style={{
                width: 380,
              }}
            />
          )}
        </Space>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default PosMessageList;
