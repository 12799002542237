const newBranch = [
  {
    name: ["id"],
    value: 0,
  },
  {
    name: ["branchCode"],
    value: "",
  },
  {
    name: ["branchName"],
    value: "",
  },
  {
    name: ["line1"],
    value: "",
  },
  {
    name: ["line2"],
    value: "",
  },
  {
    name: ["line3"],
    value: "",
  },
  {
    name: ["line4"],
    value: "",
  },
  {
    name: ["line5"],
    value: "",
  },
  {
    name: ["line6"],
    value: "",
  },
  {
    name: ["line7"],
    value: "",
  },
];

export default newBranch;
