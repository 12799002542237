import React, { useEffect, useState } from "react";
import { SearchOutlined, FileAddOutlined } from "@ant-design/icons";
import { Table, Input, Button, Popconfirm, Space, Tag, Modal } from "antd";
import {
  del,
  getAllBranches,
  save,
} from "../../infrastructure/ttposwebservice";

import BranchForm from "./branch_form";
import newBranch from "../../infrastructure/field_sets/newBranch";

const BranchList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [fields, setFields] = useState({});
  const [open, setOpen] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [record, setRecord] = useState({});

  const [searchText, setSearchText] = useState("");

  let [filteredData] = useState();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);

    getAllBranches(searchText).then((result) => {
      setLoading(false);

      filteredData = result.filter((value) => {
        return (
          value.branchCode.toLowerCase().includes(searchText.toLowerCase()) ||
          value.branchName.toLowerCase().includes(searchText.toLowerCase())
        );
      });

      console.log(searchText);

      setData(filteredData);
    });
  };

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (...sorter) => {
    const { order, field } = sorter[2];
    setSortedInfo({ columnKey: field, order });
  };
  const handleDelete = async (value) => {
    await del("branch", value);
    await loadData();
  };

  const addNew = async () => {
    setFields(newBranch);
    setOpen(1);
  };

  const edit = async (record) => {
    setFields([
      {
        name: ["id"],
        value: record.id,
      },
      {
        name: ["branchCode"],
        value: record.branchCode,
      },
      {
        name: ["branchName"],
        value: record.branchName,
      },
      {
        name: ["line1"],
        value: record.line1,
      },
      {
        name: ["line2"],
        value: record.line2,
      },
      {
        name: ["line3"],
        value: record.line3,
      },
      {
        name: ["line4"],
        value: record.line4,
      },
      {
        name: ["line5"],
        value: record.line5,
      },
      {
        name: ["line6"],
        value: record.line6,
      },
      {
        name: ["line7"],
        value: record.line7,
      },
    ]);

    setOpen(1);
  };

  const handleOk = async (values) => {
    console.log(values);
    if (values.branchCode === undefined) {
      return;
    }

    if (values.branchName === undefined) {
      return;
    }

    setConfirmLoading(true);
    var newProduct = await save("branch", values);
    await loadData();
    setConfirmLoading(false);

    setOpen(0);
  };

  const handleCancel = (e) => {
    setOpen(0);
  };

  const columns = [
    {
      title: "Şube Kodu",
      dataIndex: "branchCode",
      align: "center",
      sorter: (a, b) => a.branchCode.localeCompare(b.branchCode),
      sortOrder: sortedInfo.columnKey === "branchCode" && sortedInfo.order,
    },
    {
      title: "Şube Açıklama",
      dataIndex: "branchName",
      align: "center",
      sorter: (a, b) => a.branchCode.localeCompare(b.branchCode),
      sortOrder: sortedInfo.columnKey === "branchCode" && sortedInfo.order,
    },
    {
      title: "İşlemler",
      dataIndex: "action",
      render: (_, record) => {
        return data.length >= 1 ? (
          <Space>
            <Popconfirm
              title="Bu kaydı silmek istediğinizden emin misiz?"
              onConfirm={() => handleDelete(record)}
            >
              <Button danger type="primary">
                Sil
              </Button>
            </Popconfirm>
            <Button onClick={() => edit(record)} type="primary">
              Güncelle
            </Button>
          </Space>
        ) : null;
      },
    },
  ];

  const globalSearch = async (e) => {
    await loadData();
    console.log(searchText);
    filteredData = await data.filter((value) => {
      return (
        value.branchCode.toLowerCase().includes(searchText.toLowerCase()) ||
        value.branchName.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    console.log(searchText);

    setData(filteredData);
  };

  return (
    <div>
      <Modal
        title="Şube Kartı"
        open={open == 1}
        footer={null}
        onCancel={() => {
          setOpen(0);
        }}
      >
        <BranchForm
          fields={fields}
          onCancel={handleCancel}
          onOK={handleOk}
          onChange={(newFields) => {
            setFields(newFields);
          }}
        />
      </Modal>

      <Space>
        <Button onClick={addNew} type="primary" icon={<FileAddOutlined />}>
          Yeni Şube Kodu
        </Button>

        <Input
          style={{ width: 200, margin: 10 }}
          placeholder="Filtre"
          type="text"
          allowClear
          value={searchText}
          onChange={handleInputChange}
        />
        <Button onClick={globalSearch} type="primary" icon={<SearchOutlined />}>
          Listele
        </Button>
        <Tag color="orange">({data.length})</Tag>
      </Space>

      <br />
      <br />
      <Table
        columns={columns}
        dataSource={data}
        bordered
        loading={loading}
        onChange={handleChange}
        size="middle"
        rowKey="id"
      />
    </div>
  );
};

export default BranchList;
